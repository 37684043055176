// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY
import React from 'react';
import ZhihuCircleFilledSvg from "@ant-design/icons-svg/es/asn/ZhihuCircleFilled";
import AntdIcon from '../components/AntdIcon';

var ZhihuCircleFilled = function ZhihuCircleFilled(props, ref) {
  return React.createElement(AntdIcon, Object.assign({}, props, {
    ref: ref,
    icon: ZhihuCircleFilledSvg
  }));
};

ZhihuCircleFilled.displayName = 'ZhihuCircleFilled';
export default React.forwardRef(ZhihuCircleFilled);