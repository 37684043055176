// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY
import React from 'react';
import BankTwoToneSvg from "@ant-design/icons-svg/es/asn/BankTwoTone";
import AntdIcon from '../components/AntdIcon';

var BankTwoTone = function BankTwoTone(props, ref) {
  return React.createElement(AntdIcon, Object.assign({}, props, {
    ref: ref,
    icon: BankTwoToneSvg
  }));
};

BankTwoTone.displayName = 'BankTwoTone';
export default React.forwardRef(BankTwoTone);