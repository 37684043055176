// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY
import React from 'react';
import FieldStringOutlinedSvg from "@ant-design/icons-svg/es/asn/FieldStringOutlined";
import AntdIcon from '../components/AntdIcon';

var FieldStringOutlined = function FieldStringOutlined(props, ref) {
  return React.createElement(AntdIcon, Object.assign({}, props, {
    ref: ref,
    icon: FieldStringOutlinedSvg
  }));
};

FieldStringOutlined.displayName = 'FieldStringOutlined';
export default React.forwardRef(FieldStringOutlined);