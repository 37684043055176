// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY
import React from 'react';
import ExclamationCircleTwoToneSvg from "@ant-design/icons-svg/es/asn/ExclamationCircleTwoTone";
import AntdIcon from '../components/AntdIcon';

var ExclamationCircleTwoTone = function ExclamationCircleTwoTone(props, ref) {
  return React.createElement(AntdIcon, Object.assign({}, props, {
    ref: ref,
    icon: ExclamationCircleTwoToneSvg
  }));
};

ExclamationCircleTwoTone.displayName = 'ExclamationCircleTwoTone';
export default React.forwardRef(ExclamationCircleTwoTone);