// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY
import React from 'react';
import WeiboCircleOutlinedSvg from "@ant-design/icons-svg/es/asn/WeiboCircleOutlined";
import AntdIcon from '../components/AntdIcon';

var WeiboCircleOutlined = function WeiboCircleOutlined(props, ref) {
  return React.createElement(AntdIcon, Object.assign({}, props, {
    ref: ref,
    icon: WeiboCircleOutlinedSvg
  }));
};

WeiboCircleOutlined.displayName = 'WeiboCircleOutlined';
export default React.forwardRef(WeiboCircleOutlined);