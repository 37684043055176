// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY
import React from 'react';
import SwapRightOutlinedSvg from "@ant-design/icons-svg/es/asn/SwapRightOutlined";
import AntdIcon from '../components/AntdIcon';

var SwapRightOutlined = function SwapRightOutlined(props, ref) {
  return React.createElement(AntdIcon, Object.assign({}, props, {
    ref: ref,
    icon: SwapRightOutlinedSvg
  }));
};

SwapRightOutlined.displayName = 'SwapRightOutlined';
export default React.forwardRef(SwapRightOutlined);