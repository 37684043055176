// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY
import React from 'react';
import RedEnvelopeOutlinedSvg from "@ant-design/icons-svg/es/asn/RedEnvelopeOutlined";
import AntdIcon from '../components/AntdIcon';

var RedEnvelopeOutlined = function RedEnvelopeOutlined(props, ref) {
  return React.createElement(AntdIcon, Object.assign({}, props, {
    ref: ref,
    icon: RedEnvelopeOutlinedSvg
  }));
};

RedEnvelopeOutlined.displayName = 'RedEnvelopeOutlined';
export default React.forwardRef(RedEnvelopeOutlined);